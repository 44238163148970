// libs
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { styled } from '@nykaa/ui-components';
import get from 'lodash/get';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// components
import Subscription from 'shared/components/Footer/components/Subscription';
import Links from 'shared/components/Footer/components/Links';
import Categories from 'shared/components/Footer/components/Categories/DesktopCategories';
import Terms from 'shared/components/Footer/components/Terms';

// redux
import { isFooterVisible as _isFooterVisible } from 'shared/store/app/selectors';

// helpers
import { isWebView } from 'shared/helpers/utils/url';

// defs
interface IFooterProps {
  isFooterVisible: boolean;
}

// styles
const Container = styled.div`
  content-visibility: auto;
  contain-intrinsic-size: 850px;
`;

function Footer({ isFooterVisible }: IFooterProps) {
  const location = useLocation();
  const showFooterCategories = get(useRemoteConfig('footerSection'), 'showCategories', false);
  const shouldHideFooter = isWebView(location) || !isFooterVisible;

  if (shouldHideFooter) {
    return null;
  }

  return (
    <Container>
      <Subscription />
      <Links />
      {showFooterCategories && <Categories />}
      <Terms />
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  isFooterVisible: _isFooterVisible(state),
});

export default memo(connect(mapStateToProps)(Footer));
