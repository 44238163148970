// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// constants
import { DWEB_MAX_WIDTH } from 'shared/styles/base';
import { categories } from 'shared/components/Footer/data';

// components
import Category from './components/Category';

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing.spacing120} 0 ${theme.spacing.spacing160} 0`};

  .content {
    display: flex;
    max-width: ${DWEB_MAX_WIDTH}px;
    font-family: inherit;
    color: #666;
    margin: 0 auto;
    padding: ${({ theme }) => `0 ${theme.spacing.spacing50}`};
  }
`;

function Categories() {
  return (
    <Container>
      <div className="content">
        {categories.map((cat, idx) => (
          <Category {...cat} key={idx} />
        ))}
      </div>
    </Container>
  );
}

export default memo(Categories);
