// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// helpers
import { storeAnalyticsDataInLocal } from 'shared/helpers/storage/storeData';

const Container = styled.div`
  flex: 1;
  ${({ theme }) => theme.typography.bodySmall};

  .ct-title {
    margin-bottom: ${({ theme }) => theme.spacing.spacing40};
    text-transform: uppercase;
    ${({ theme }) => theme.typography.subTitleSmall};
  }

  .ct-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .ct-item {
    padding-bottom: ${({ theme }) => theme.spacing.spacing20};
  }

  .ct-anc {
    text-decoration: none;
    color: #666;
  }
`;

function Category({ title, links }: Footer.Category) {
  const onLinkClicked = () => {
    storeAnalyticsDataInLocal({ footerCategoriesLinkClicked: 1 });
  };

  return (
    <Container>
      <div className="ct-title">{title}</div>
      <ul className="ct-list">
        {links.map((link, idx) => (
          <li className="ct-item" key={idx}>
            <a className="ct-anc" href={link.href} onClick={onLinkClicked}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default memo(Category);
